<template>
   <section class="content-container">
      <SectionTabs :tabs="tab_component"/>
      <router-view/>
   </section>
</template>

<!-- <template>
   <div class="library">
      <div class="library-sidebar">
         <SideBar />
      </div>
      <div class="library__container">
         <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />
         <div class="library__body">
            <div class="library__body-top">
               <SectionTitle :title="title" subtitle="" />
               <ProfileNav />
            </div>
            <div class="tabs">
               <SectionTabs :tabs="tab_component" />
            </div>
            <router-view />
            <footerSec></footerSec>
         </div>
      </div>
   </div>
</template> -->

<script>
import SectionTabs from "@/components/Section/SectionTabs.vue";
/* import SideBar from "@/components/SideBar.vue";
import SectionTitle from "@/components/Section/SectionTitle.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue";
import footerSec from "@/components/Section/SectionFooter.vue"; */

export default {
   components: { 
      SectionTabs, 
/*       SideBar, 
      ProfileNav, 
      SectionTitle, 
      footerSec  */
   },
   data() {
      return {
         title: "Biblioteca",
         tab_component: [
            {
               name: "Inicio",
               route: "library-list",
            },
            {
               name: "Agregar",
               route: "library-add",
            },
            {
               name: "Categorías",
               route: "library-add-categories",
            },
         ],
         subtitle: "",
      };
   },
   mounted() {
      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 100);
      if (
         this.$route.name == "library-list" ||
         this.$route.name == "library-add-categories"
      ) {
         this.subtitle = "";
      }
      setInterval(() => {
         this.checkIfUserIsLogged();
         if (this.$route.name == "library-edit") {
            this.tab_component[1].name = "Editar";
            this.subtitle =
               "Completa los siguientes campos para editar un libro.";
         } else {
            this.tab_component[1].name = "Agregar";
            this.subtitle =
               "Completa los siguientes campos para publicar un libro.";
         }
      }, 1000);
   },
   methods: {
      goTo(route) {
         var current_route = this.$route.name;
         if (route == current_route) {
            return;
         } else {
            this.$router.push({ name: route });
         }
      },
      activeTab(tab) {
         var current_route = this.$route.name;
         if (tab == current_route) {
            return "active";
         }
      },
   },
};
</script>